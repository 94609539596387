<template>
  <div class="publicationQueryItem">
    <router-link :to="{ name: 'PublicationDetail', query: { id: item.id } }">
      <div class="clickable">
        <div
          :alt="`${item.title}`"
          class="publicationQueryItem__img"
          :style="
            `background-image: url(${item.screenshot ||
              require('@/assets/image/Publication/Empty.jpg')})`
          "
        ></div>

        <h3 class="publicationQueryItem__title">{{ item.title }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        diameter: '',
        height: '0',
        id: '173',
        length: '0',
        level: '教育品',
        screenshot:
          'https://collections.culture.tw/ShowGalImage.aspx?FROM=GEP&SYSUID=112&IMG=5UK25VMWM4MB09MO5AMWM5MWMI0OMCM4MFMPMCM6MIM7M3565A0XM4MZMCMCKWKL57KH57MBM70VMZKC5SMGKG5CK7KGKX53KD59K85V5U0V57MW5RMNKGM15OKN04M6M60GMD01M2MZ0AM4M601MA',
        sizeUnit: 'cm',
        thickness: '0',
        title: '台灣金屬礦業學員結業證書',
        url:
          'https://collections.culture.tw/gep_collectionsweb/collection.aspx?GID=MFMPME',
        weight: '',
        weightUnit: '',
        width: '0'
      })
    }
  }
}
</script>

<style lang="scss">
.publicationQueryItem {
  width: 25%;
  padding: 3.5rem;

  &__img {
    padding-bottom: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-align: center;
    margin-top: 1.25rem;
  }
}

@media (max-width: 1005px) {
  .publicationQueryItem {
    width: 33.333%;
  }
}

@media (max-width: 768px) {
  .publicationQueryItem {
    width: 50%;
  }
}

@media (max-width: 625px) {
  .publicationQueryItem {
    width: 100%;
    padding: 2rem 0;
  }
}
</style>
