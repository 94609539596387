var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"publicationQuery container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}},[_c('PublicationQuery',{model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('div',{staticClass:"publicationQuery__formRow"},[_c('BaseLabel',{staticClass:"publicationQuery__label",attrs:{"labelFor":'baseSelect',"text":'類別檢索'}}),(_vm.typeList.length > 0)?_c('BaseSelect',{staticClass:"publicationQuery__select",attrs:{"options":_vm.typeList},on:{"input":_vm.onChangeType},model:{value:(_vm.currentType),callback:function ($$v) {_vm.currentType=$$v},expression:"currentType"}}):_vm._e()],1),(_vm.currentType)?_c('ul',{staticClass:"publicationQuery__filter"},_vm._l((_vm.levelList),function(level){return _c('a',{key:level.ctId,staticClass:"publicationQuery__filterBtn clickable",attrs:{"href":"#"},on:{"click":function($event){return _vm.onClickLevel(level)}}},[_vm._v(" "+_vm._s(level.title)+" "+_vm._s(level.count)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"publicationQuery__itemContainer"},[_vm._l((_vm.collectionItemListFilter),function(collectionItem,index){return [_c('PublicationQueryItem',{key:collectionItem.id,attrs:{"item":collectionItem}}),(
            (index + 1) % 4 === 0 &&
              index + 1 !== _vm.collectionItemListFilter.length
          )?_c('hr',{key:`${index}hr`,staticClass:"publicationQuery__hr"}):_vm._e(),(
            (index + 1) % 3 === 0 &&
              index + 1 !== _vm.collectionItemListFilter.length
          )?_c('hr',{key:`${index}hr--three`,staticClass:"publicationQuery__hr--three"}):_vm._e(),(
            (index + 1) % 2 === 0 &&
              index + 1 !== _vm.collectionItemListFilter.length
          )?_c('hr',{key:`${index}hr--half`,staticClass:"publicationQuery__hr--half"}):_vm._e(),(index + 1 !== _vm.collectionItemListFilter.length)?_c('hr',{key:`${index}hr--single`,staticClass:"publicationQuery__hr--single"}):_vm._e()]})],2),_c('b-pagination-nav',{attrs:{"number-of-pages":Math.ceil(this.collectionItemWithLevel.length/16).toString(),"base-url":"?page=","use-router":"","align":"center","size":"lg","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('hr',{staticClass:"publicationQuery__hr--bottom"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }