<template>
  <section class="publicationQuery container">
    <form @submit.prevent="onSearch">
      <PublicationQuery v-model="keyword" />
      <div class="publicationQuery__formRow">
        <BaseLabel
          class="publicationQuery__label"
          :labelFor="'baseSelect'"
          :text="'類別檢索'"
        />
        <BaseSelect
          v-if="typeList.length > 0"
          class="publicationQuery__select"
          :options="typeList"
          v-model="currentType"
          @input="onChangeType"
        />
      </div>

      <ul v-if="currentType" class="publicationQuery__filter">
        <a href="#"
          v-for="level in levelList"
          :key="level.ctId"
          class="publicationQuery__filterBtn clickable"
          @click="onClickLevel(level)"
        >
          {{ level.title }} {{ level.count }}
        </a>
      </ul>
    </form>

    <div class="publicationQuery__itemContainer">
      <template v-for="(collectionItem, index) in collectionItemListFilter">
        <PublicationQueryItem :key="collectionItem.id" :item="collectionItem" />
        <hr
          v-if="
            (index + 1) % 4 === 0 &&
              index + 1 !== collectionItemListFilter.length
          "
          :key="`${index}hr`"
          class="publicationQuery__hr"
        />
        <hr
          v-if="
            (index + 1) % 3 === 0 &&
              index + 1 !== collectionItemListFilter.length
          "
          :key="`${index}hr--three`"
          class="publicationQuery__hr--three"
        />
        <hr
          v-if="
            (index + 1) % 2 === 0 &&
              index + 1 !== collectionItemListFilter.length
          "
          :key="`${index}hr--half`"
          class="publicationQuery__hr--half"
        />
        <hr
          v-if="index + 1 !== collectionItemListFilter.length"
          :key="`${index}hr--single`"
          class="publicationQuery__hr--single"
        />
      </template>
    </div>

<!--    <BasePagination-->
<!--      :total="collectionItemWithLevel.length"-->
<!--      :page-size="pageSize"-->
<!--      :current-page="currentPage"-->
<!--      @current-change="onCurrentPageChange"-->
<!--    />-->

    <b-pagination-nav
      v-model="currentPage"
      :number-of-pages="Math.ceil(this.collectionItemWithLevel.length/16).toString()"
      base-url="?page="
      use-router
      align="center"
      size="lg"
      first-number
      last-number
    ></b-pagination-nav>

    <hr class="publicationQuery__hr--bottom" />
  </section>
</template>
<script>
import PublicationQuery from '@/components/Publication/PublicationQuerySearch.vue'
import BaseLabel from '@/components/BaseLabel.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import PublicationQueryItem from '@/components/Publication/Query/QueryItem.vue'
import BasePagination from '@/components/BasePagination.vue'

// Api
import { apiGetCollections } from '@/api/webAPI'
import $ from "jquery";

export default {
  components: {
    PublicationQuery,
    BaseLabel,
    BaseSelect,
    PublicationQueryItem,
    BasePagination
  },
  data() {
    return {
      typeList: [],
      currentType: {
        text: '全部',
        value: ''
      },
      currentLevel: '',
      collectionResponse: [],
      keyword: '',
      currentPage: 1,
      pageSize: 16
    }
  },
  computed: {
    filterWithType() {
      if (this.currentType.value) {
        const filterWithType = this.collectionResponse.filter(
          collectionResponse =>
            collectionResponse.type === this.currentType.value
        )
        return filterWithType
      }
      return []
    },
    collectionItemList() {
      if (this.currentType.value) {
        const result = []
        this.filterWithType.map(type => {
          const collections = type.collections.map(_collections => ({
            ..._collections,
            ctTitle: type.title
          }))
          result.push(...collections)
        })

        return result
      }

      // 全部
      const result = []
      this.collectionResponse.map(type => {
        result.push(...type.collections)
      })
      return result
    },
    collectionItemWithLevel() {
      if (this.currentLevel) {
        return this.collectionItemList.filter(
          _result => _result.ctTitle === this.currentLevel
        )
      }
      return this.collectionItemList
    },
    collectionItemListFilter() {
      const pageSize = this.pageSize
      const startIndex = pageSize * (this.currentPage - 1)
      const endIndex = pageSize * this.currentPage

      return this.collectionItemWithLevel.slice(startIndex, endIndex)
    },
    levelList() {
      if (!this.currentType.value) return

      const result = []
      for (const collectionItem of this.filterWithType) {
        result.push({
          title: collectionItem.title,
          count: collectionItem.collections.length,
          ctId: collectionItem.ctId,
          type: collectionItem.type
        })
      }

      return result
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },
  methods: {
    onClickLevel(level) {
      const query = this.$route.query
      this.$router.push({
        name: 'PublicationQuery',
        query: {
          ...query,
          level: level.title,
          page: 1
        }
      })
    },
    onChangeType() {
      const query = this.$route.query
      this.$router.push({
        name: 'PublicationQuery',
        query: {
          ...query,
          type: this.currentType.value,
          page: 1,
          level: ''
        }
      })
    },
    onSearch() {
      const query = this.$route.query

      this.$router.push({
        name: 'PublicationQuery',
        query: {
          ...query,
          keyword: this.keyword
        }
      })
    },
    async fetchData() {
      const { keyword, type, page, level } = this.$route.query
      if (keyword) this.keyword = keyword
      if (type) {
        this.currentType = {
          text: type,
          value: type
        }
      }

      this.currentLevel = level || ''

      this.currentPage = +page || 1

      const collectionResponse = (
        await apiGetCollections({
          keyword
        })
      ).data.data

      this.collectionResponse = collectionResponse

      const allType = collectionResponse.map(collection => collection.type)
      const uniqueAllType = [...new Set(allType)]
      const newTypeList = uniqueAllType.map(type => ({
        text: type,
        value: type
      }))
      this.typeList = [
        {
          text: '全部',
          value: ''
        },
        ...newTypeList
      ]
    },
    onCurrentPageChange(page) {
      this.currentPage = page
      const query = this.$route.query
      this.$router.push({
        name: 'PublicationQuery',
        query: {
          ...query,
          page: page
        }
      })
    }
  },
  created() {
    this.fetchData()
  },
  mounted(){
    $('ul.pagination >:first-child').attr('title', '上一頁')
    $('ul.pagination >:last-child').attr('title', '下一頁')
    // $("button").removeAttr('tabindex');
  }
}
</script>
<style lang="scss">
.publicationQuery {
  padding: 4.8rem;

  &__formRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
    flex-wrap: wrap;
  }

  &__select {
    width: 20.5rem;
  }

  &__label {
    margin-right: 2.6rem;
  }

  &__filter {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  &__filterBtn {
    display: inline-block;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #727171;
    padding: 0.7rem 1.2rem;
    border: 1px solid #d0a805;
    border-radius: 35px;
    margin: 1.2rem 1.2rem 1.2rem 0;
  }

  &__itemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__hr,
  &__hr--bottom {
    width: 100%;
    margin: 0;
    border: none;
    border-bottom: 1px dashed #d0a805;
  }

  &__hr--bottom {
    margin-top: 10rem;
  }
}

@media (max-width: 1005px) {
  .publicationQuery {
    &__hr {
      display: none;
    }
    &__hr--three {
      width: 100%;
      margin: 0;
      border: none;
      border-bottom: 1px dashed #d0a805;
    }
  }
}

@media (max-width: 768px) {
  .publicationQuery {
    padding: 2rem;

    &__select {
      width: 100%;
    }

    &__hr--half {
      width: 100%;
      margin: 0;
      border: none;
      border-bottom: 1px dashed #d0a805;
    }

    &__hr--three {
      display: none;
    }

    &__hr--bottom {
      margin-top: 4.8rem;
    }
  }
}

@media (max-width: 625px) {
  .publicationQuery {
    &__hr--half {
      display: none;
    }

    &__hr--single {
      width: 100%;
      margin: 0;
      border: none;
      border-bottom: 1px dashed #d0a805;
    }

    &__label {
      margin-bottom: 1.2rem;
    }
  }
}
</style>
